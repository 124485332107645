/**
 * Created by ApolloLogix on 2022-11-20.
 */


// we need to fix: fetch_lib.js? [sm]:10 Uncaught (in promise) ReferenceError: json is not defined
function fetch_data1_v1(data, callback, item, path = '/data/get_json_data/') {
    //var fetch_data;
    item = item ? `/${item}` : ''
    path = `${path}${data}${item}`
    console.log('fetch path:', path)
    fetch(path).then(function (response) {
        // The API call was successful!
        return response.json()
    }).then(function (json) {
        console.log('json response:')
        console.log(json)
        if (json == null) {
            console.log('no fetch_data found')
            json = []
        }
        $(document).ready(function () {
            callback(json)
        });
    })
        .catch(function (err) {
            // There was an error
            console.warn('an error occured', err);
            json = []
            $(document).ready(function () {
                callback(json)
            });
        });
}

// we need to fix: fetch_lib.js? [sm]:10 Uncaught (in promise) ReferenceError: json is not defined
/**
 * @param {*} data
 * @param {*} callback
 * @param {string} item
 * @param path {string} - default: '/data/get_json_data/' - data is the CodeIgniter controller name
 */
function fetch_data1(data, callback, item, path = '/data/get_json_data/') {
    //var fetch_data;
    item = item ? `/${item}` : ''
    path = `${path}${data}${item}`
    console.log('fetch path:', path)
    fetch(path)
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Network response was not ok.');
        })
        .then(data => {
            console.log('json response:',data);
            if (data == null) {
                console.log('no fetch_data found')
                data = []
            }
            $(document).ready(function () {
                callback(data)
            });
        })
        .catch(error => {
            console.error('Error:', error);
            console.warn('an error occurred', error);
            data = []
            $(document).ready(function () {
                callback(data)
            });
        });
}


function insert_htnl() {
    return function (html) {
        // This is the HTML from our response as a text string
        //console.log(html);

        // Convert the HTML string into a document object
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const html_selected = doc.getElementById("page_to_fetch")
        console.log('html_selected:', html_selected)

        document.querySelector('#fetch_here').innerHTML = html_selected.outerHTML;

    };
}

function fetch_page(page_to_fetch) {
    fetch('/' + page_to_fetch).then(function (response) {
        // The API call was successful!
        return response.text();
    }).then(insert_htnl()).then(function () {
            console.log('part2')
            new WOW().init(); // the wow needs to be initialized after the fetch is done
            // wp styling also has to be done again if a page was fetched
            //add_mdb_to_wp()
            page_ready_alx = true
        }
    ).catch(function (err) {
        // There was an error
        console.warn(`could not fetch page: ${page_to_fetch} `, err);
    });
}
